.fade-edges {

    /* mask-image: linear-gradient(transparent,
        black 20%,
        black 80%,
        transparent 100%);
    -webkit-mask-image: linear-gradient( to right,transparent,
        black 20%,
        black 80%,
        transparent 100%); */
    position: relative;
  }


  .fade-edges:before {
    content: "";
    background: rgba(19,19,19,.85);
    width: 14.5%;
    display: block;
    position: absolute;
    top: 0;
    z-index: 99;
    height: 100%;
  }

  .fade-edges:after {
    content: "";
    background: rgba(19,19,19,.85);
    width: 14.5%;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    height: 100%;
  }