.autocomplete-menu{
    top: 100%;
    background-color: #1C1C1C;
    border-radius: none;
    box-shadow: 0 20px 50px 5px rgba(0,0,0,0.60);
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
}
.autocomplete-option{
    border-bottom: 1px solid hsla(0,0%,100%,.05);
    backgroundColor: #1C1C1C;
    padding: 0.9375rem 1.25rem;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    cursor: pointer;
    color: #767474
}
.autocomplete-list{
    max-height: 140px;
    overflow-y: scroll;
    /* padding-bottom: 4px; */
    /* padding-top: 4px; */
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    -webkit-scrollbar-color: hsla(0,0%,100%,.2) transparent;
    -moz-scrollbar-color: hsla(0,0%,100%,.2) transparent;
    -ms-scrollbar-color: hsla(0,0%,100%,.2) transparent;
    scrollbar-color: hsla(0,0%,100%,.2) transparent;
    -webkit-scrollbar-width: thin;
    -moz-scrollbar-width: thin;
    -ms-scrollbar-width: thin;
    scrollbar-width: thin;
}
.option-active{
    color: #fff !important
}