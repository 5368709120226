.wav-patch{
    visibility: hidden
}

@media screen and (min-width : 760px){
    .wav-patch{
        max-width: 10.625rem;
        height: 1.875rem !important;
        margin-right: 1.25rem;
        width: 960px;
        visibility: visible
    }
}

.list-wav-patch{
    width: 146px;
    height: 36px;
}