.price-tool-title{
    text-align: left;
    font-size: .8rem;
    margin-top: .4rem;
    margin-bottom: .5rem;
}
.price-tool-desc{
    text-align: left;
    font-size: .76rem;
    margin-bottom: .5rem;
}