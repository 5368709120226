.wavesurfer-region {
    background-color: rgba(255, 255, 255, 0.05) !important;
    cursor: pointer !important;
}
.wavesurfer-handle {
    background-color: red !important;
    width: 2px !important;
    cursor: default !important;
}
.waveform #trackWave wave{
    cursor: default !important;
}