@keyframes slideIn {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideIn {
    animation-name: slideIn;
    animation-duration: 300ms;
    z-index: 110 !important;
}

@keyframes slideOut {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0);
    }
}

.slideOut {
    animation-name: slideOut;
    animation-duration: 300ms;
}