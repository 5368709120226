.allProjects{
    border: 3px solid
}
.singleProjCaro{
    border: 3px solid
}
.trackCard{
    border: 1px solid
}
@media screen and (min-width: 760px){
    .display-mobile{
        visibility: hidden !important;
        display: none !important
    }
}

/* .ghost-class{
    opacity: 1;
    background-color: red !important;
}
.sortable-drag{
    opacity: 1;
    background-color: blue !important
} */