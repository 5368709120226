:root{
  --leftwidth: 0%;
  --rightwidth: 50%;

}
.fade-edges-details {

    /* mask-image: linear-gradient(transparent,
        black 20%,
        black 80%,
        transparent 100%);
    -webkit-mask-image: linear-gradient( to right,transparent,
        black 20%,
        black 80%,
        transparent 100%); */
    position: relative;
  }


  .fade-edges-details:before {
    content: "";
    background: rgba(19,19,19,.85);
    width: var(--leftwidth);
    display: block;
    position: absolute;
    top: 0;
    z-index: 99;
    height: 100%;
  }

  .fade-edges-details:after {
    content: "";
    background: rgba(19,19,19,.85);
    width: var(--rightwidth);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    height: 100%;
  }