    
.cart-count{   
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
    border-radius: 9999px;
    --bg-opacity: 1;
    background-color: #EB192D;
    background-color: rgba(235, 25, 45, var(--bg-opacity));
}